import React, { useEffect } from "react"
import moment from "moment"
import { useContext } from "react"
import reactStringReplace from "react-string-replace"
import tw, { GlobalStyles } from "twin.macro"
import { Card } from "../components/atoms/Card"
import Icon from "../components/atoms/Icon"
import { LanguageContext } from "../contexts/Language"
import { Image } from "../components/atoms/Image"
import { graphql, navigate } from "gatsby"
import { Global } from "@emotion/react"
import customGlobalStyles from "../styles/global"

const couponToPrint: React.FC<{ data: any }> = ({ data }) => {
  const coupon = Array.isArray(data?.coupon?.nodes) && data.coupon.nodes[0]
  const { _ } = useContext(LanguageContext)
  useEffect(() => {
    setTimeout(() => window.print(), 500)
    window.addEventListener("afterprint", event => {
      navigate(-1)
    })
    return () => {
      window.removeEventListener("afterprint", event => {
        navigate(-1)
      })
    }
  }, [])

  return (
    <div css={[tw`flex justify-center mt-12`]}>
      <GlobalStyles />
      <Global styles={customGlobalStyles} />
      <div
        css={[tw`flex-none w-80 ml-7 mr-4 h-auto min-h-[570px]`, tw`md:(mx-0)`]}
      >
        <Card
          shadowLevel={3}
          css={[
            tw`relative bg-white h-full flex content-between  pb-6 border-2 border-dashed border-black m-3 mb-5 overflow-visible md:(p-0)`,
          ]}
        >
          <span
            css={[
              tw`absolute text-center mx-auto bg-gray-700 text-gray-300 text-xs rounded-full px-2 pb-0.5 -top-2 left-1/2 transform -translate-x-1/2`,
            ]}
          >
            {_("Coupon")}
          </span>
          <div css={tw`grid gap-2 content-between h-full pb-3 pt-1.5`}>
            <Image
              imageData={coupon?.image?.image}
              alt={coupon?.image?.alt}
              objectFit="contain"
              css={[tw`h-32 mx-auto md:(h-32)`]}
            />

            <p css={[tw`font-semibold text-xl pt-0 text-center block`]}>
              {reactStringReplace(
                coupon?.name,
                /\[(.*?)\]/gi,
                (match, i) => ""
              )}
            </p>
            <div
              css={[
                tw`font-semibold text-red-500 text-4xl text-center md:min-h-[140px]`,
              ]}
            >
              {reactStringReplace(
                coupon?.offer,
                /\[(.*?)\]/gi,
                (match, i) => ""
              )}
            </div>
            {coupon?.disclaimers && (
              <div css={[tw`text-xs`]}>
                {coupon?.disclaimers[0]?.disclaimer}
              </div>
            )}
            <div
              css={[
                tw`font-book text-gray-700 text-xs text-center flex justify-center`,
              ]}
            >
              {_("Exp")}: {moment(coupon?.expires).format("M-D-YY")}
              <Icon.Warning
                color="red-400"
                css={[tw`max-h-3 mt-0.5 ml-1 inline`]}
              />
            </div>
          </div>
        </Card>
      </div>{" "}
    </div>
  )
}

export const query = graphql`
  query CouponPageQuery($id: String) {
    coupon: allSanityPageCoupon(filter: { _id: { eq: $id } }) {
      nodes {
        _id
        name
        category
        type
        expires
        offer
        image {
          ...SanityImageData
        }
        disclaimers {
          code
          disclaimer
        }
        starts
      }
    }
  }
`

export default couponToPrint
